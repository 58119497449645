/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  Hide,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  Show,
  Spinner,
  Text,
  Textarea,
  useTheme,
} from '@chakra-ui/react';
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import rf from 'src/requests/RequestFactory';
// import AppButton from 'src/components/AppButton';
import styles from 'src/styles/page/Chat.module.scss';
import { ERole, IChatGptDetail, IMessage } from 'src/types';
import { useAppDispatch } from 'src/hooks/hooks';
import { setDataChatGpt } from 'src/store/chat';
import { Drawer } from 'antd';
import PDFTab from 'src/components/pdf-tab';
import MessageCard from 'src/components/MessageCard';
import { LOADING_CHAT } from 'src/constants';
import { parsePageNumber } from 'src/utils/chat';
import { IcSend } from 'src/components/icons';
import IcClose from 'src/components/icons/IcClose';

const CHAT_INPUT_CONTAINER = 'chat-input-container';
const CHAT_MESSAGE_ID = `chat-azure-message`;

const getChatMessageId = (index: number) => {
  return `${CHAT_MESSAGE_ID}-${index}`;
};

function ChatAzure() {
  const [input, setInput] = useState<string>('');
  const [fileNamePDF, setFileNamePDF] = useState<string>('');
  const ref = useRef<any>(null);
  const refBottomDiv = useRef<any>(null);
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number | undefined>(undefined);
  const [isOpenSecondTab, setIsOpenSecondTab] = useState<boolean>(false);
  const [chatInputHeight, setChatInputHeight] = useState(0);

  const [detailData, setDetailData] = useState<IChatGptDetail>({
    _id: '',
    temperature: 0,
    model_type: '',
    source: '',
    user_id: '',
    messages: [],
  });
  const dispatch = useAppDispatch();

  const { chat_id, bot_id } = useParams();

  const scrollToBottom = () => {
    refBottomDiv.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToMessage = (id: string) => {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 0);
  };

  const fetchChatConversationId = useCallback(async () => {
    setLoading(true);
    try {
      const res = await rf
        .getRequest('ChatRequest')
        .getChatConversationById(chat_id);

      setDetailData(res.data);
      scrollToMessage(getChatMessageId(res.data.messages.length - 1));
    } catch (e) {
      console.log(e, 'Error');
    }
    scrollToBottom();
    setLoading(false);
  }, [chat_id]);

  useLayoutEffect(() => {
    setChatInputHeight(
      document.getElementById(CHAT_INPUT_CONTAINER)?.offsetHeight || 0,
    );
  }, [isOpenSecondTab]);

  const updateNavbarList = async () => {
    try {
      const res = await rf.getRequest('ChatRequest').getChatConversations();
      const { data } = res;
      dispatch(setDataChatGpt({ data }));
    } catch (e) {
      console.log(e, 'Error');
    }
  };

  const sendChatConversation = async () => {
    setLoading(true);
    setInput('');

    const newMessageByUser = [
      ...detailData?.messages,
      {
        role: ERole.USER,
        content: input,
      },
    ];
    setDetailData((pre: IChatGptDetail) => {
      return {
        ...pre,
        messages: [
          ...newMessageByUser,
          {
            role: ERole.ASSISTANT,
            content: LOADING_CHAT,
          },
        ],
      };
    });
    scrollToMessage(getChatMessageId(detailData.messages.length + 1));
    try {
      const res = await rf.getRequest('ChatRequest').sendChatMessage({
        content: input,
        conversation_id: chat_id,
      });
      const { answer } = res.data;

      setDetailData((pre: IChatGptDetail) => {
        return {
          ...pre,
          messages: [
            ...newMessageByUser,
            {
              role: ERole.ASSISTANT,
              content: answer,
            },
          ],
        };
      });

      if (!detailData.messages.length) {
        updateNavbarList();
      }
    } catch (e) {
      setDetailData((pre: IChatGptDetail) => {
        return {
          ...pre,
          messages: [
            ...newMessageByUser,
            {
              role: ERole.ASSISTANT,
              content: 'Sorry, I have some problems',
            },
          ],
        };
      });
    }

    scrollToMessage(getChatMessageId(newMessageByUser.length - 1));

    setLoading(false);
  };

  const onClickPDF = async (fileName: string) => {
    const { filename, pageNumber: page } = parsePageNumber(fileName);
    setLoading(true);
    setPageNumber(page);
    setIsOpenSecondTab(true);
    if (!Number.isInteger(Number(page))) {
      setFileNamePDF(fileName);
      setLoading(false);
      return;
    }
    if (fileName === filename) {
      setLoading(false);
      return;
    }

    try {
      // await rf.getRequest('ChatRequest').getFilePDF(bot_id, filename);
      setFileNamePDF(`${filename}`);
    } catch (error: any) {
      setFileNamePDF(fileName);
    }
    setLoading(false);
  };

  const onToggleTab = () => {
    setIsOpenSecondTab(false);
  };

  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }
    return () => {
      setIsOpenSecondTab(false);
    };
  }, [ref, chat_id]);

  useEffect(() => {
    fetchChatConversationId();
  }, [fetchChatConversationId]);

  return (
    <Flex height="100%" paddingInline={isOpenSecondTab ? 15 : 0}>
      <Box
        className={cn(styles.chatContainer)}
        style={
          {
            '--chat-input-container': `${chatInputHeight}px`,
          } as React.CSSProperties
        }
        w={{ base: 'full', lg: isOpenSecondTab ? '50%' : '80%' }}
      >
        <Box position={'relative'} className={cn(styles.chatWrapper)}>
          <Box pt={'30px'} px={'20px'} className={cn(styles.chatContent)}>
            {detailData?.messages?.map((el: IMessage, index) => (
              <MessageCard
                message={el}
                onOpenPDF={onClickPDF}
                key={detailData._id + index + el.role}
                id={getChatMessageId(index)}
                fullWidth={isOpenSecondTab}
              />
            ))}
            <Box ref={refBottomDiv} />
          </Box>
        </Box>
        <Box className={cn(styles.bottomWrapper)} id={CHAT_INPUT_CONTAINER}>
          {/* <Flex justifyContent={'center'}>
              <AppButton
                borderRadius={'6px'}
                size={'lg'}
                variant="gray"
                className={cn(styles.btn_login)}
                mx={'auto'}
              >
                <Image src={icRefresh} mr={2} alt="icon" /> Regenerate response
              </AppButton>
            </Flex> */}
          <Box className={cn(styles.inputWrapper)}>
            <Box maxW={'full'} mx={'auto'}>
              <InputGroup size={'lg'} className={cn(styles.inputGroup)} mt={8}>
                <InputRightElement pr={4}>
                  <IconButton
                    variant="outline"
                    colorScheme="teal"
                    aria-label="Send "
                    isDisabled={loading || !input}
                    onClick={sendChatConversation}
                    className={cn(styles.btnSend)}
                    icon={
                      <Image as="div" alt="icon">
                        <IcSend
                          linearGradient={[
                            theme.colors.brand[600],
                            theme.colors.brand[300],
                          ]}
                        />
                      </Image>
                    }
                  />
                </InputRightElement>
                <Textarea
                  ref={ref}
                  autoFocus={true}
                  className={cn(styles.input)}
                  variant="filled"
                  placeholder="Ask me anything..."
                  value={input}
                  border="none"
                  resize="none"
                  onChange={(event) => {
                    setInput(event.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && input && !loading) {
                      e.preventDefault();
                      sendChatConversation();
                    }
                  }}
                />
              </InputGroup>
              <Text
                pt={4}
                className={cn(
                  styles.inputDescription,
                  isOpenSecondTab ? styles['inputDescription__withPDF'] : '',
                )}
              >
                Free research Previe. Chatbot may produce inaccurated
                information about people, places, or facts.{' '}
                <span>Chatbot may 12 Version.</span>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box w={{ base: 0, lg: isOpenSecondTab ? '50%' : 0 }}>
        <Hide breakpoint="lg">
          {isOpenSecondTab && bot_id && (
            <PDFTab
              botId={bot_id}
              page={pageNumber}
              isLoading={loading}
              onClose={onToggleTab}
              fileName={fileNamePDF}
            />
          )}
        </Hide>
        <Show below="lg">
          <Drawer
            rootClassName={cn(styles.drawerPDF)}
            width={'700px'}
            placement="right"
            onClose={onToggleTab}
            open={isOpenSecondTab}
            closeIcon={
              <Image as="div" alt="close">
                <IcClose stroke={'white'} />
              </Image>
            }
          >
            {!!fileNamePDF && bot_id && (
              <PDFTab
                botId={bot_id}
                isLoading={loading}
                isMobile={true}
                fileName={fileNamePDF}
              />
            )}
          </Drawer>
        </Show>
      </Box>
    </Flex>
  );
}

export default ChatAzure;
